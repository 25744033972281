import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSun,
  faMoon,
  faVolumeUp,
  faVolumeMute,
  faMicrophone,
  faMicrophoneSlash,
  faSpinner,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer } from "react-toastify";

function LMSStyle({
  isTTSEnabled,
  setIsTTSEnabled,
  handleStopTTS,
  darkMode,
  setDarkMode,
  displayedText,
  initialMessage,
  avatar,
  question,
  handleVoiceInput,
  isListening,
  inputRef,
  handleInputChange,
  handleKeyDown,
  handleInputFocus,
  handleSendMessage,
  isLoading,
  isTyping,
  handleSkipTyping,
  messagesContainerRef,
  serverAddress,
}) {
  return (
    <div className="fixed inset-0 flex items-center justify-center overflow-hidden">
      <div className="relative w-full h-full bg-transparent z-0 flex flex-col overflow-hidden">
        <img
          src={
            isTyping
              ? "https://i.imgur.com/qrZ0xO4.gif"
              : avatar
              ? `${serverAddress}/avatar/${avatar}`
              : "/images/default_user.jpg"
          }
          alt="Background"
          className="fixed top-0 left-0 h-full object-cover z-0"
          style={{ width: '40%' }}
        />
        <div className="absolute top-4 left-4 flex items-center space-x-2 z-[1000]">
          {/* Dark mode button */}
          <button
            onClick={() => setDarkMode(!darkMode)}
            className="p-2 rounded-full bg-blue-600 hover:bg-blue-700 text-white flex items-center justify-center transition duration-200"
          >
            <FontAwesomeIcon icon={darkMode ? faMoon : faSun} />
          </button>
          {/* Mute/Unmute TTS button */}
          <button
            onClick={() => {
              if (isTTSEnabled) {
                handleStopTTS();
                setIsTTSEnabled(false);
              } else {
                setIsTTSEnabled(true);
              }
            }}
            className="p-2 rounded-full bg-blue-600 hover:bg-blue-700 text-white flex items-center justify-center transition duration-200"
          >
            <FontAwesomeIcon icon={isTTSEnabled ? faVolumeUp : faVolumeMute} />
          </button>
        </div>
        {/* Chat area that grows to fill available space */}
        <div
          className={`flex-grow overflow-y-auto p-4 relative z-10 ${
            darkMode
              ? "bg-gray-900 bg-opacity-80 text-white"
              : "bg-white bg-opacity-5 text-black"
          }`}
          ref={messagesContainerRef}
        >
          {displayedText ? (
            <div
              className="flex flex-col items-start my-2 absolute top-1/3 left-[40%] w-[60%]"
              onClick={handleSkipTyping}
            >
              <div className="p-4 rounded-[15px] bg-blue-500 text-white text-left text-xl relative w-full">
                <span>{displayedText}</span>
                <div
                  className="absolute top-2.5 left-[-15px] w-0 h-0 border-t-[15px] border-t-transparent border-b-[15px] border-b-transparent border-r-[15px] border-r-blue-500"
                ></div>
              </div>
            </div>
          ) : (
            <div
              className="flex flex-col items-start my-2 absolute top-1/3 left-[40%] w-[60%]"
              onClick={handleSkipTyping}
            >
              <div className="p-4 rounded-[15px] bg-blue-500 text-white text-left text-xl relative w-full">
                <span>{initialMessage}</span>
                <div
                  className="absolute top-2.5 left-[-15px] w-0 h-0 border-t-[15px] border-t-transparent border-b-[15px] border-b-transparent border-r-[15px] border-r-blue-500"
                ></div>
              </div>
            </div>
          )}
        </div>
        {/* Chatbox always at the bottom */}
        <div className="p-4 z-10">
          <div className="w-full h-12 border border-gray-600 flex items-center rounded-full p-2 bg-white bg-opacity-20 backdrop-filter backdrop-blur-md">
            <button
              onClick={handleVoiceInput}
              className={`h-full p-2 rounded-full bg-blue-600 hover:bg-blue-700 text-white flex items-center justify-center transition duration-200 mr-2 ${
                isListening ? "pulsating" : ""
              }`}
            >
              <FontAwesomeIcon
                icon={isListening ? faMicrophoneSlash : faMicrophone}
              />
            </button>
            <input
              ref={inputRef}
              value={question}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              onFocus={handleInputFocus}
              className="h-full w-full p-2 outline-none bg-transparent text-left rounded-full"
              type="text"
              placeholder="Type a message..."
            />
            <button
              onClick={handleSendMessage}
              className="h-full p-2 rounded-full bg-blue-600 hover:bg-blue-700 text-white flex items-center justify-center transition duration-200 ml-2"
              disabled={isLoading || isTyping}
            >
              {isLoading || isTyping ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                <FontAwesomeIcon icon={faPaperPlane} />
              )}
            </button>
          </div>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
}

export default LMSStyle;
