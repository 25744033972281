import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrophone, faMicrophoneSlash, faSpinner, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer } from "react-toastify";

function HeygenStyle({
  displayedText,
  initialMessage,
  question,
  handleVoiceInput,
  isListening,
  inputRef,
  handleInputChange,
  handleKeyDown,
  handleSendMessage,
  isLoading,
  isTyping,
  handleSkipTyping,
  messagesContainerRef,
}) {
  return (
    <div className="fixed inset-0 flex items-center justify-center">
      <div className="relative w-full h-full bg-transparent z-0">
        <video
          id="mediaElement"
          className="absolute inset-0 w-full h-full object-cover z-0"
          autoPlay
          playsInline
        ></video>
        <div className="flex flex-col justify-between h-full w-full relative z-10 text-black">
          <div className="h-full overflow-y-auto p-4 relative" ref={messagesContainerRef}>
            {displayedText ? (
              <div
                className="flex flex-col items-end my-2 absolute top-[66%] left-4 right-4"
                onClick={handleSkipTyping}
              >
                <div className="p-4 rounded-[15px] bg-blue-500 text-white text-left text-xl relative w-full">
                  <span>{displayedText}</span>
                </div>
              </div>
            ) : (
              <div className="flex flex-col items-end my-2 absolute top-[66%] left-4 right-4">
                <div className="p-4 rounded-[15px] bg-blue-500 text-white text-left text-xl relative w-full">
                  <span>{initialMessage}</span>
                </div>
              </div>
            )}
          </div>
          <div className="p-4">
            <div className="w-full h-12 border border-gray-600 flex items-center rounded-full p-2 bg-white bg-opacity-20 backdrop-filter backdrop-blur-md">
              {/* Microphone button for voice input */}
              <button
                onClick={handleVoiceInput}
                className={`h-full p-2 rounded-full bg-blue-600 hover:bg-blue-700 text-white flex items-center justify-center transition duration-200 mr-2 ${
                  isListening ? "pulsating" : ""
                }`}
              >
                <FontAwesomeIcon
                  icon={isListening ? faMicrophoneSlash : faMicrophone}
                />
              </button>
              <input
                ref={inputRef}
                value={question}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                className="h-full w-full p-2 outline-none bg-transparent text-left rounded-full"
                type="text"
                placeholder="Type a message..."
              />
              <button
                onClick={handleSendMessage}
                className="h-full p-2 rounded-full bg-blue-600 hover:bg-blue-700 text-white flex items-center justify-center transition duration-200 ml-2"
                disabled={isLoading || isTyping}
              >
                {isLoading || isTyping ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  <FontAwesomeIcon icon={faPaperPlane} />
                )}
              </button>
            </div>
            <ToastContainer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeygenStyle;
